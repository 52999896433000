<template>
<!--    <iframe src="rawHtml" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>-->
    <iframe :srcdoc="rawHtml" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>
<!--    <div v-html="rawHtml"/>-->
</template>

<script>

    import {getMopsFormData} from '../../services/backend.service';

    export default {
        data() {
            return {
                // Just like JSX! Oh wait...
                rawHtml: null
            }
        },
        mounted() {
            let transactionId = this.$router.history.current.query.transactionid;
            this.getFormHtml(transactionId);
        },
        methods:{
            getFormHtml: function (transactionId) {
                getMopsFormData(transactionId).then(res => {

                    if (res.data.success && res.data.data) {
                        this.rawHtml = res.data.data;
                        console.log(this.rawHtml);
                    }
                }).catch(e => {
                    //this.getReceiptDataError = this.$t('error-2');
                    console.log(`%c Error in Mops.vue - method: getFormHtml - ${e}`, 'color:red');
                });
            }
        }
    }
</script>

<style scoped></style>
